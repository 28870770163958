import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Content, { HTMLContent } from '../components/Content';
import RouterTracker from '../components/RouterTracker';

import ThemeContext from '../context/ThemeContext';

const UnderwritingPageTemplateStyles = styled.article(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
`
);

export const UnderwritingPageTemplate = ({
  title,
  content,
  contentComponent,
  breakpoints,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <UnderwritingPageTemplateStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <div className='body'>
            <section className='typesetter'>
              <h1 className='title'>{title}</h1>
              <PageContent content={content} />
            </section>
          </div>
        </UnderwritingPageTemplateStyles>
      )}
    </ThemeContext.Consumer>
  );
};

UnderwritingPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const UnderwritingPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const breakpoints = useBreakpoint();

  return (
    <UnderwritingPageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      breakpoints={breakpoints}
    />
  );
};

UnderwritingPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UnderwritingPage;

export const underwritingPageQuery = graphql`
  query UnderwritingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
